import React from 'react';
import { securityContent } from '../../utils/contentData/securityContent';
import Layout from '@/components/Layout';
import { seoConfiguration } from '@/utils/seo-config';
import './security-and-compliance.scss';

const SecurityAndCompliancePage = () => {
  const { title: titleSeo, image, description } = seoConfiguration.security;
  const scrollTo = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    e.preventDefault();
    const section = document.getElementById(id);
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    history.pushState(null, '', `#${id}`);
  };

  return (
    <>
      <Layout image={image} title={titleSeo} description={description}>
        <div className="security-page">
          <h1>Security and Compliance</h1>
          <div className="security-page__date">
            — Last update: October 20, 2023 —
          </div>
          <div className="security-page__contents">
            <ol>
              {securityContent.map((section, index) => (
                <li key={index}>
                  <a
                    href={`#${section.id}`}
                    onClick={(e) => scrollTo(e, section.id)}
                  >
                    {section.name}
                  </a>
                  <ol>
                    {section.topics?.map((topic, topicIndex) => (
                      <li key={topicIndex}>
                        <a
                          href={`#${section.id}`}
                          onClick={(e) => scrollTo(e, topic.id)}
                        >
                          {topic.name}
                        </a>
                      </li>
                    ))}
                  </ol>
                </li>
              ))}
            </ol>
          </div>
          <div className="security-page__main">
            {securityContent.map((section, index) => (
              <div className="security-page__section" key={index}>
                <h2 id={section.id}>{section.name}</h2>
                {section.content}
                {section.topics?.map((topic, topicIndex) => (
                  <React.Fragment key={topicIndex}>
                    <h3 id={topic.id}>{topic.name}</h3>
                    {topic.content}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SecurityAndCompliancePage;
