export const securityContent = [
  {
    name: 'Compliance',
    id: 'compliance',
    topics: [
      {
        name: 'SOC 2',
        id: 'soc-2',
        content: (
          <>
            SOC 2 is a globally recognized auditing standard for service
            organizations that demonstrates adequate controls and processes.
            Akuity has successfully completed the SOC 2 Type 2 audit. Akuity’s
            SOC 2 report covers the trust services principles and criteria
            security and availability. A copy of the most recent audit report is
            available in our{' '}
            <a href="https://trust.akuity.io/" target="_blank" rel="noreferrer">
              Trust Report
            </a>
            .
          </>
        )
      }
    ]
  },
  {
    name: 'Data Security',
    id: 'data-security',
    content: (
      <>
        <p>
          All of Akuity’s services are hosted in Amazon Web Services (AWS)
          facilities in the United States. Services are distributed across
          multiple AWS availability zones. These zones are hosted in physically
          separate data centers, protecting services against single data center
          failures.
        </p>
        <p>
          More information about AWS security practices is on{' '}
          <a
            href="https://aws.amazon.com/security/"
            target="_blank"
            rel="noreferrer"
          >
            their cloud security page.
          </a>
        </p>
      </>
    ),
    topics: [
      {
        name: 'Data classification',
        id: 'data-classification',
        content: (
          <>
            <p>
              Akuity classifies the data we own, use, create, and maintain into
              the following categories:
            </p>
            <ul>
              <li>Confidential – Customer and personal data</li>
              <li>
                Internal – Akuity-internal operational data that should not be
                disclosed
              </li>
              <li>
                Public – For example, the marketing material and content on this
                website
              </li>
            </ul>
          </>
        )
      },
      {
        name: 'Encryption at rest',
        id: 'encryption-at-rest',
        content: (
          <p>
            Akuity uses the AWS-managed data stores Aurora RDS and S3 to store
            customer data, including backups. All these AWS services have been
            configured to use encryption at rest using AES with 256-bit keys.
          </p>
        )
      },
      {
        name: 'Secrets and encryption key management',
        id: 'secrets-and-encryption-key-management',
        content: (
          <p>
            Akuity uses AWS Secret Manager for securely storing and managing
            secrets used by services. Akuity uses AWS Key Management Service
            (KMS) to encrypt and decrypt these secrets as well as manage all
            encryption keys in use by Akuity services. Access to secrets and
            encryption keys are restricted to the services on a least privilege
            basis and are managed by the Akuity infrastructure team.
          </p>
        )
      },
      {
        name: 'Separation of environments',
        id: 'separation-of-environments',
        content: (
          <p>
            Akuity fully separates and isolates our production, staging, and
            development networks and environments.
          </p>
        )
      }
    ]
  },
  {
    name: 'Product security',
    id: 'product-security',
    topics: [
      {
        name: 'Secure development',
        id: 'secure-development',
        content: (
          <>
            <p>
              Akuity practices continuous delivery. Our processes and automation
              allow us to safely and reliably roll out changes to our cloud
              infrastructure and web-based applications rapidly. We deploy new
              changes to production multiple times a day.
            </p>
            <ul>
              <li>
                All code changes are requested through pull requests and are
                subjected to code reviews and approval before being merged into
                the master and production branches.
              </li>
              <li>
                Akuity uses GitHub and{' '}
                <a
                  href="https://dependabot.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Dependabot
                </a>{' '}
                to automatically create pull requests to update outdated
                dependencies.
              </li>
              <li>
                Akuity uses static source code analysis tools like{' '}
                <a
                  href="https://www.sonarsource.com/products/sonarcloud/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sonar Cloud
                </a>{' '}
                to analyze source code changes to identify potential code
                quality issues or security weaknesses.
              </li>
              <li>
                Akuity uses{' '}
                <a href="https://sentry.io/" target="_blank" rel="noreferrer">
                  Sentry
                </a>{' '}
                to track errors in the web and backend services.
              </li>
            </ul>
          </>
        )
      },
      {
        name: 'External security testing',
        id: 'external-security-testing',
        content: (
          <p>
            In addition to our internal security scanning and testing program,
            Akuity employs third-party firms to conduct extensive penetration
            tests of all applications and cloud infrastructure on a regular
            basis. Findings from these penetration tests are prioritized,
            triaged, and remediated by Akuity’s engineering team. Akuity also
            runs weekly automated Penetration testing against our websites to
            ensure we are always staying on top of new threats.
          </p>
        )
      }
    ]
  },
  {
    name: 'Infrastructure and network security',
    id: 'infrastructure-and-network-security',
    topics: [
      {
        name: 'Transport security',
        id: 'transport-security',
        content: (
          <p>
            Akuity requires the use of TLS to secure the transport of data, both
            on the internal network between services as well as the public
            network between the Akuity applications and the Akuity cloud
            infrastructure. Akuity’s TLS configuration requires at least TLS
            version 1.2 and the use of strong cipher suites, which support
            important security features such as Forward Secrecy.
          </p>
        )
      },
      {
        name: 'External attack surface',
        id: 'external-attack-surface',
        content: (
          <p>
            Akuity only exposes public (web) applications and APIs to the public
            internet. All other services are only available on the internal
            network and accessible by employees using a VPN. The external attack
            surface is monitored for changes by a third-party service.
          </p>
        )
      },
      {
        name: 'Network segmentation',
        id: 'network-segmentation',
        content: (
          <p>
            Network segmentation is a foundational aspect of Akuity’s cloud
            security strategy. Akuity achieves segmentation boundaries at
            various layers of its cloud infrastructure. Akuity uses a
            multi-account strategy within AWS to isolate production,
            development, test environments, and domains such as logging,
            security, and marketing. Within AWS, Akuity uses VPCs, security
            groups, network access control lists, and subnets to isolate
            services further.
          </p>
        )
      },
      {
        name: 'Intrusion detection and prevention',
        id: 'intrusion-detection-and-prevention',
        content: (
          <p>
            Akuity maintains an extensive centralized logging environment in
            which network, host, and application logs are collected at a central
            location. Akuity has also enabled detailed audit trails with
            critical service providers like Google Workspaces, GitHub, and AWS
            (CloudTrail). These logs and audit trails are analyzed by automated
            systems for security events, anomalous activity, and undesired
            behavior. If any suspicious activity is observed, our engineering
            team is alerted, and they will review and prioritize the issues to
            ensure they are resolved within our specified SLAs.
          </p>
        )
      }
    ]
  },
  {
    name: 'Organizational security',
    id: 'organizational-security',
    topics: [
      {
        name: 'Security training',
        id: 'security-training',
        content: (
          <p>
            All new hires must attend the security awareness training as part of
            their onboarding. All employees are required to attend the annual
            security awareness training. Akuity engineers must attend an annual
            security training designed specifically for engineers.
          </p>
        )
      },
      {
        name: 'Asset inventory',
        id: 'asset-inventory',
        content: (
          <p>
            Akuity maintains an accurate and up-to-date inventory of all its
            networks, services, servers, and employee devices. Access to Akuity
            customer data is provided on an explicit need-to-know basis and
            follows the principle of least privilege. Customer data is audited
            and monitored by the security team. All Akuity employees have signed
            a non-disclosure agreement.
          </p>
        )
      },
      {
        name: 'Security incident management',
        id: 'security-incident-management',
        content: (
          <p>
            The security team at Akuity aggregates logs and audit trails from
            various sources at a central location and uses tools to analyze,
            monitor, and flag anomalous or suspicious activity. Akuity’s
            internal processes define how alerts are triaged, investigated, and,
            if needed, escalated. Both customers and non-customers are
            encouraged to disclose any potential security weaknesses or
            suspected incidents to Akuity Security.
          </p>
        )
      },
      {
        name: 'Information security policies',
        id: 'information-security-policies',
        content: (
          <p>
            Akuity maintains several information security policies that form the
            basis of our information security program. All Akuity employees are
            required to review these policies as part of their onboarding. These
            security policies cover the following topics and are available to
            our customers on our{' '}
            <a
              href="https://trust.akuity.io/documents"
              target="_blank"
              rel="noreferrer"
            >
              Trust page
            </a>
            .
          </p>
        )
      },
      {
        name: 'Background Checks',
        id: 'background-checks',
        content: (
          <>
            <p>
              Akuity conducts background checks for all new hires, including
              verification on the following:
            </p>
            <ul>
              <li>Identity verification</li>
              <li>Global watchlist check</li>
              <li>National criminal records check</li>
              <li>County criminal records check</li>
              <li>(U.S. only) Sex offender registry check</li>
            </ul>
          </>
        )
      }
    ]
  },
  {
    name: 'Operational security',
    id: 'operational-security',
    topics: [
      {
        name: 'Backups and disaster recovery',
        id: 'backups-and-disaster-recovery',
        content: (
          <p>
            All Akuity customer data is stored redundantly at multiple AWS data
            centers (availability zones) to ensure availability. Akuity has
            well-tested backup and restoration procedures in place, allowing
            quick recovery in the case of single data center failures and
            disasters. Customer data is continuously backed up and stored
            off-site (At a secondary AWS region). The restoration of backups is
            fully tested each quarter to ensure that our processes and tools
            work as expected.
          </p>
        )
      },
      {
        name: 'Endpoint security',
        id: 'endpoint-security',
        content: (
          <p>
            Akuity exclusively uses Apple MacBook devices. These devices are all
            centrally managed through the internal mobile device management
            solution, which allows us to enforce security settings such as full
            disk encryption, network and application firewall, automatic
            updates, screen time-outs, and anti-malware solutions.
          </p>
        )
      },
      {
        name: 'Risk management and assessment',
        id: 'risk-management-and-assessment',
        content: (
          <p>
            Akuity performs periodic risk analysis and assessment to ensure that
            our information security policies and practices meet the
            requirements and applicable regulatory obligations.
          </p>
        )
      },
      {
        name: 'Contingency Planning',
        id: 'contingency-planning',
        content: (
          <p>
            The Akuity operations team includes service continuity and threat
            remediation among its top priorities. We keep a contingency plan in
            case of unforeseen events, including risk management, disaster
            recovery, and customer communication sub-plans that are tested and
            updated on an ongoing basis and thoroughly reviewed for gaps and
            changes at least annually.
          </p>
        )
      }
    ]
  },
  {
    name: 'Enterprise security',
    id: 'enterprise-security',
    content: (
      <p>
        Akuity Enterprise includes all our general security measures, plus
        additional features and enhancements to provide even more customization
        and privacy.
      </p>
    ),
    topics: [
      {
        name: 'Single sign-on (SSO)',
        id: 'single-sign-on',
        content: (
          <p>
            Akuity supports single sign-on (SSO) for Enterprise customers. Using
            the customer’s existing identity management solution, Akuity
            provides an easy and secure way for companies to manage their team
            members’ access. Akuity supports identity providers like Google
            Workspaces, Azure Active Directory, and Okta. Akuity also supports
            both SAML and OAuth-based OpenID Connect.
          </p>
        )
      },
      {
        name: 'Role-based access control (RBAC)',
        id: 'role-based-access-control',
        content: (
          <p>
            Akuity supports role-based access control, which means the access of
            team members within an organization is dictated by their role
            (member, administrator, or owner). Administrators can assign team
            members specific roles or revoke access using the Akuity account
            dashboard.
          </p>
        )
      },
      {
        name: 'Email Security',
        id: 'email-security',
        content: (
          <>
            <p>
              The Akuity service includes email notifications. Sender policy
              framework (SPF) is a system to prevent email address spoofing and
              minimize inbound spam. We have SPF records set through AWS
              Route53, our domain name service (DNS), and domain-based message
              authentication, reporting, and conformance (DMARC) set up for
              monitoring reports to prevent the possibility of phishing scams.
              Akuity users can see the TXT records on dmarc.Akuity.io and
              akuity.io:
            </p>
            <div className="security-page__code">
              {`$ dig _dmarc.akuity.io TXT +short

"v=DMARC1;p=none;sp=none;adkim=r;aspf=r;pct=100;fo=1;rua=mailto:4v9sefpm@ag.us.dmarcian.com;ruf=mailto:4v9sefpm@fr.us.dmarcian.com;"

$ dig akuity.io TXT +short | grep spf

"v=spf1 include:_spf.google.com include:amazonses.com include:21537535.spf10.hubspotemail.net include:mailer.shopifyemail.com ~all"`}
            </div>
          </>
        )
      }
    ]
  },
  {
    name: 'Security vulnerability disclosure',
    id: 'security-vulnerability-disclosure',
    content: (
      <p>
        If you want to disclose a potential security vulnerability or have
        concerns about an Akuity product, please contact security@akuity.io.
        Please include a description of the security vulnerability, steps to
        reproduce, and the impact the vulnerability may have.
      </p>
    )
  }
];
